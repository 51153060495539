import styles from './index.module.scss'
import message from '@c/message'
import Image from '@c/image'
import { AREA_SEARCH_GET } from '@/constant'
import Link from 'next/link'
import { useRef } from 'react'
import cm from 'classnames'
import { useEffect, useState } from 'react'
import { useShowLogin, useWindowWidth } from '@/utils/hooks'
import { vodCollected, vodCancelCollected } from '@/api'
import EventBus from '@/utils/emitter'
import { pcStore } from '@/store'
import { useRecoilValue } from 'recoil'
import { hotSearchData, showSearchFocus } from '@/store'
import { useRouter } from 'next/router'






const Index = props => {
  

  const {
    showTitle = true, //是否需要展示标题行
    data,
    isHome = false, //是否是首页
    source,
    top = 0,
    isDetail = false, //是否是详情页
    hideCollect = false,
    className,
    lessItems = false
  } = props
  const [list, setList] = useState(data.cards || [])
  const topData = useRecoilValue(hotSearchData)



  useEffect(() => {
    EventBus.on('firstLevelPage', res => {
      const currCards = res.find(item => item.name === data.name)?.cards || []
      setList(currCards)
    })
    return () => {
      EventBus.offAll('firstLevelPage')
    }
  }, [data.name])

  const setShowLogin = useShowLogin()
  const router = useRouter()


  
const updateCollectStatus = (data, id, val) => {
  const updateData = data.map(item => {
    if (item.id === id) {
      item.collect = val
    }
    return item
  })
  return updateData
}


// 更新数据&用在搜素需要更新的页面
useEffect(() => {
  setList(data.cards || [])
}, [data])


  const toPlay = item => {
    if (item.label === '直播') {
      let url = `/live/${item.url ? item.url : ''}`
      if (router.pathname === '/live/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    } else {
      let url = `/vod/detail/${item.id}`
      if (router.pathname === '/vod/detail/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    }
  }


  


  const collected = async (type, id) => {
    const token = localStorage.getItem('token')
    if (!token) {
      setShowLogin(true)
      return
    }
    let res = null
    if (type) {
      res = await vodCancelCollected(id, token)
    } else {
      res = await vodCollected(id, token)
    }
    if (!res.code) {
      message({ content: type ? '取消收藏成功' : '收藏成功！' })
      setList(updateCollectStatus(list, id, type ? 2 : 1))
      //做处理
    } else {
      message({ content: res.msg })
    }
  }

  // 收藏
  const toggleCollect = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    collected(data.collect === 1, data.id)
  }


  return (
    <>
     

     <div>

     

     </div>
      <div className={cm(styles.container)}>
        
          <div className={styles.leftContainer}>
          <div className={styles.moduleName}>{data?.name}</div>
          <div className={styles.content}>
            {list.map((item,index)=>{
              return (
                <Link href={`/vod/detail/${item.id}`}>
                  <div className={cm(styles.cardList)}>


                  {/* <Image  src={item.img} className={styles.cover}  /> */}

                  <Image className={styles.img} src={item.img || item.picurl} alt={item.name || '详情海报'} />
                    {/* <img src={item.img} className={styles.cover} alt={item.desc?item.desc:'暂无描述'} /> */}

                    {
                      item.state == 2?(
                        <img src='/api/images/gfxl.png' alt='官方线路' className={styles.official}  />
                      ):''
                    }

                     
                     {
                     
                        <div className={styles.score}>
                          <div>{item.tmdbScore?item.tmdbScore.toFixed(1):''}</div>
                          <div>
                          {hideCollect ? null : (
                        <>
                          {item.collect === 1 ? (
                            <div
                              className={styles.addLove}
                              html-txt="从我的收藏移除"
                              onClick={e => toggleCollect(e, item)}
                            >
                              <img loading="lazy" src="/api/images/h-collected" alt="已经藏" />
                            </div>
                          ) : (
                            <div
                              className={styles.addLove}
                              html-txt="添加到我的收藏"
                              onClick={e => toggleCollect(e, item)}
                            >
                              <img loading="lazy" src="/api/images/h-collect" alt="添加收藏" />
                            </div>
                          )}
                        </>
                      )}
                          </div>
                     </div>
                      
                     }

                     {/* 收藏信息 */}
                     {/* {item.tmdbScore}
                      {item.doubanScore}
                      {item.time}
                      {item.label} */}
                    

                     <div className={cm('textEllipsis',styles.name)}>{item.name}</div>   
                     <div className={cm('textEllipsis',styles.desc)}>{item.desc?item.desc:'暂无描述'}</div>  
                  </div>
                </Link>
              )


            })}
            </div>

          </div>
      </div>
    </>
  )

  
}

export default Index
